<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">{{ $t('BILLS.UNFISCALIZED_BILLS') }}</h3>
            </div>
            <div class="card-toolbar">
              <button class="btn btn-light-primary" @click="refreshBills">
                <span class="svg-icon svg-icon-md mr-0">
                  <inline-svg src="media/svg/icons/Media/Repeat.svg" />
                </span>
              </button>
            </div>
          </div>
          <div class="card-body py-0">
            <div>
              <div v-if="isBillsLoaded" class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center table-head-bg"
                >
                  <thead>
                    <tr class="text-uppercase">
                      <th class="pl-6">
                        <span>Status</span>
                      </th>
                      <th>
                        <span>Jir</span>
                      </th>
                      <th>
                        <span>Zki</span>
                      </th>
                      <th>
                        <span>Imei</span>
                      </th>
                      <th>
                        <span>Pp</span>
                      </th>
                      <th>
                        <span>{{ $t('GENERAL.AMOUNT') }}</span>
                      </th>
                      <th>
                        <span>{{ $t('GENERAL.DATE_AND_TIME') }}</span>
                      </th>
                      <th class="text-right">
                        <span>{{ $t('GENERAL.ACTION') }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(bill, key) in bills" :key="key">
                      <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                        <span>--</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ bill.jir }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ bill.zki }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ bill.imei }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ bill.meta.business_space_label }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span class="text-dark font-weight-bold"
                          >{{ bill.amount }} €</span
                        >
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ bill.createdAt | formatDateTime }}</span>
                      </td>

                      <td
                        class="text-right"
                        :class="{ 'border-top-0': key === 0 }"
                      >
                        <span
                          ><button
                            class="btn btn-sm btn-light-primary"
                            @click="fiscalBill(bill.id)"
                          >
                            {{ $t('BILLS.FISCAL') }}
                          </button></span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="col-12 text-center my-5 pt-5 pb-2">
                <b-spinner variant="primary"></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import axios from 'axios'
import ApiService from '../../../core/services/api.service'

export default {
  name: 'UnfiscalizedBills',
  data() {
    return {
      bills: [],
      isBillsLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompanyOib']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Unfiscalized Bills', route: '/bills/unfiscalized' },
    ])

    axios
      .get(
        `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.currentUserCompanyOib}&isFiscal=false&order[createdAt]=desc&pagination=false`
      )
      .then(({ data }) => {
        this.bills = [...data['hydra:member']]
        this.isBillsLoaded = true
      })
  },
  methods: {
    refreshBills() {
      this.isBillsLoaded = false
      this.bills = []

      axios
        .get(
          `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.currentUserCompanyOib}&isFiscal=false&order[createdAt]=desc&pagination=false`
        )
        .then(({ data }) => {
          this.bills = [...data['hydra:member']]
          this.isBillsLoaded = true

          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Reload success',
          })
        })
    },
    fiscalBill(id) {
      ApiService.get('subsequent-fiscal', id).then(() => {})
    },
  },
}
</script>
